<template>
	<div class="page container">

		<div class="page__info">
			<div class="page__info--title">
				Transactions
			</div>
		</div>

		<div>
			<transactions
				entryRoute="admin"
			/>
		</div>
	</div>
</template>

<script>
	import Transactions  from '@/components/transactions/Transactions';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Transactions'
		},
		components: {
			Transactions
		},
		data: () => ({}),
		computed: {},
		mounted () {}
	};

</script>
